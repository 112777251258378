import React, {useEffect, useState} from 'react';
import './App.css';

const colors = [
  '#E55B4D',
  '#E56841',
  '#E56C3D',
  '#E57039',
  '#E57535',
  '#E57931',
  '#E57D2D',
  '#E58129',
  '#E58525',
  '#E68A20',
  '#E68E1C',
  '#E69218',
  '#E69614',
  '#E69A10',
  '#E69F0C',
  '#E6A308',
  '#E6A704',
  '#E6AC00',
  '#DCAD05',
  '#D3AD09',
  '#CAAE0E',
  '#C1AE12',
  '#B8AF16',
  '#AFAF1A',
  '#A6B01F',
  '#9DB023',
  '#93B127',
  '#8AB12B',
  '#81B230',
  '#78B234',
  '#6FB338',
  '#66B33C',
  '#5DB441',
  '#54B445',
].reverse()
type McBlock = {
  gen_utime: number
  master: any
}

function App() {
  const [mcBlock, setMcBlock] = useState<McBlock>();
  const update = () => {
    fetch("https://gql-testnet.venom.foundation/11ea6a846cb54ddab9a49d24218f1f50/graphql", {
      "credentials": "omit",
      "headers": {
        "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:109.0) Gecko/20100101 Firefox/112.0",
        "Accept": "*/*",
        "Accept-Language": "en-US,en;q=0.5",
        "content-type": "application/json",
        "Sec-Fetch-Dest": "empty",
        "Sec-Fetch-Mode": "cors",
        "Sec-Fetch-Site": "same-origin"
      },
      "referrer": "https://gql-testnet.venom.foundation/graphql",
      "body": "{\"operationName\":null,\"variables\":{},\"query\":\"{\\n  blocks(\\n    filter: {workchain_id: {eq: -1}}\\n    orderBy: {path: \\\"seq_no\\\", direction: DESC}\\n    limit: 1\\n  ) {\\n    gen_utime\\n    master {\\n      shard_hashes {\\n        descr {\\n          gen_utime\\n          want_merge\\n          want_split\\n          before_merge\\n          before_split\\n          min_ref_mc_seqno\\n        }\\n        workchain_id\\n        shard\\n      }\\n    }\\n  }\\n}\\n\"}",
      "method": "POST",
      "mode": "cors"
    }).then((r) => {
      r.json().then((data) => {
        const mc_block = data.data.blocks[0]
        setMcBlock(mc_block)
      })
    });
  }
  useEffect(() => {
    update()
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      update()
    }, 15000);
    return () => clearInterval(interval);
  }, [])
  const shards = mcBlock ? mcBlock.master.shard_hashes.map((shard: any) => {

    const diff = Math.floor(Date.now() / 1000) - shard.descr.gen_utime
    return <div className="shard">
      id: {shard.shard.slice(0, 3)}
      <br/>
      diff: <div style={{color: colors[Math.ceil(diff / 2) - 1]}}>{diff}</div>
      <br/>
      {shard.descr.want_merge ? <div style={{color: "green"}}>want merge</div> : ''}
      {shard.descr.before_merge ? <div style={{color: "green"}}>before merge</div> : ''}
      {shard.descr.want_split ? <div style={{color: "red"}}>want split</div> : ''}
      {shard.descr.before_split ? <div style={{color: "red"}}>before split</div> : ''}
    </div>
  }) : []
  return (
    <div>
      <button onClick={update}>Update</button>
      <p>
        Master Chain Block diff = {mcBlock ? Math.floor(Date.now() / 1000) - mcBlock?.gen_utime : "unknown"}
      </p>
      <p>
        Shards: {mcBlock ? mcBlock.master.shard_hashes.length : 0}
      </p>
      <div className="shards">
        {shards}
      </div>
    </div>
  );
}

export default App;
